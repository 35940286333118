<template>
  <div class="relative py-12">
    <img
      src="@/assets/imgs/banner-bg.png"
      alt=""
      class="object-cover object-center absolute top-0 left-0 w-full h-full"
    />
    <div class="relative z-10 flex justify-center items-center">
      <Exchange>
        <div class="text-primary text-xl mr-2 whitespace-nowrap">
          新增折價券
        </div>
      </Exchange>
    </div>
  </div>
  <div class="container mt-8">
    <Data-Type
      v-model="couponData.form.dataType"
      class="sm:w-full w-auto"
      :opts="couponData.dataType"
    />
    <List></List>
  </div>
</template>

<script setup>
import { provide } from 'vue';
import Exchange from '@/modules/coupon/_Exchange.vue';
import List from '@/modules/coupon/List.vue';
import useCoupon from '@/modules/coupon/useCoupon.js';
import useExchange from '@/modules/coupon/useExchange.js';
import DataType from '@/components/filter/DataType.vue';
const couponData = useCoupon({ type: 'mine' });
const exchangeData = useExchange({ rows: couponData.rows });
provide('couponData', couponData);
provide('exchangeData', exchangeData);
couponData.fetcher();
</script>
