<template>
  <div v-loading="loading">
    <div
      v-if="rows.length"
      class="grid grid-cols-4 gap-6 mt-4 mb-12 lg:grid-cols-3 md:grid-cols-1 md:gap-4"
    >
      <Coupon-Item v-for="row in rows" :key="row.id" :row="row" />
    </div>
    <div v-else>
      <p class="text-lg text-center text-gray">
        您目前沒有可用的折扣券，請輸入折扣代碼
      </p>
      <img src="@/assets/imgs/role-upset.png" alt="" class="mt-12 mx-auto" />
    </div>
  </div>
</template>
<script setup>
import { inject } from 'vue';
import CouponItem from '@/modules/coupon/_Item.vue';
const { rows, loading } = inject('couponData');
</script>
